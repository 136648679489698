<template>
	<tab-pane title="Provisioning" id="tabProvisioning">
		<span slot="title">
			<i class="ni ni-cloud-upload-96"></i>
			&nbsp;Provisioning
		</span>

		<div class="row justify-content-md-center">
			<div class="col-xl-12" v-if="!downloadVisible">
				<card header-classes="bg-transparent">
					<!-- Error alert -->
					<div v-if="error.show" class="alert alert-danger">
						<p>{{ error.message }}</p>
					</div>

					<!-- Target platform type -->
					<div class="row" v-if="!showDone">
						<div class="col-md">
							<h3>Select target platform type</h3>
							<p>(Note: Only available platforms are enabled)</p>
						</div>

						<div class="col-md text-md-right">
							<b-dropdown
								id="dropSelectedVendor"
								menu-class="w-100"
								class="px-1"
							>
								<template #button-content>
									<img v-if="orchestrationType.spaIcon" :src="getImgUrl(orchestrationType.spaIcon)" />
									&nbsp;
									{{ orchestrationType.desc || "Select a target vendor" }}
								</template>

								<form @submit.prevent class="px-2 py-2">
									<input type="search" v-model="search" class="form-control form-control-sm"
										placeholder="Search for a vendor" autofocus>
								</form>

								<b-dropdown-item v-for="o in resultQuery" :key="o.name" id="btnSelectOrchestration"
									@click="selectOrchestration(o.name)">
									<span>
										<img :src="getImgUrl(o.spaIcon)" />
										&nbsp;{{ o.desc }}
									</span>
								</b-dropdown-item>
							</b-dropdown>

							<base-button
								id="btnAuthenticate"
								v-if="orchestrationType && orchestrationType.externalSSO && !isProvisioningAuth"
								type="primary"
								@click="authProvisioning"
								class="my-2 mx-1"
							>Authenticate</base-button>

							<base-button 
								id="btnReAuthenticate"
								v-if="orchestrationType && orchestrationType.externalSSO && isProvisioningAuth"
								@click="authProvisioning"
								type="danger"
								class="my-2 mx-1"
							>Re-authenticate</base-button>
						</div>
					</div>

					<!-- File upload -->
					<Vue-Dropzone
						:class="{ 'disabled': !orchestrationType.name || (orchestrationType.externalSSO && !isProvisioningAuth) }"
						v-show="!hideDropzone" id="dropzone" ref="dropzone" :options="dropzoneOptions"
						style="cursor: pointer" @vdropzone-sending="attachJWT" />

					<!-- Fields -->
					<div class="row pt-3" v-if="orchestrationType.name && !showDone">
						<hr />

						<div class="col">
							<!-- Mandatory customer field -->
							<h4>Customer name</h4>
							<base-input id="inputCustomerName" v-model="custName" placeholder="Acme Inc."
								@keyup.enter="scheduleProvisioning" />

							<!-- SOW Field -->
							<h4 v-if="getGroupProfile.SPASOW">{{ getGroupProfile.SPASOWLabel }}</h4>
							<base-input v-if="getGroupProfile.SPASOW" id="inputSOW" v-model="sowId"
								:placeholder="`${getGroupProfile.SPASOWLabel}`" @keyup.enter="scheduleProvisioning" />

							<!-- Phase Id Field -->
							<h4 v-if="getGroupProfile.SPAPhase">{{ getGroupProfile.SPAPhaseLabel }}</h4>
							<base-input v-if="getGroupProfile.SPAPhase" id="inputSOW" v-model="phaseId"
								:placeholder="`${getGroupProfile.SPAPhaseLabel}`" @keyup.enter="scheduleProvisioning" />

							<PartnerType ref="PartnerType" :profileData="getGroupProfile"
								@updated="sp => selectedPartner = sp" />

							<div v-for="field in orchestrationType.fields" :key="field.fieldName">
								<h4>{{ field.desc }}</h4>
								
								<base-input :id="`input-${field.fieldName}`"
									v-if="field.type == 'text' || field.type == 'url' || field.type == 'password' || field.type == 'email' || field.type == 'number'"
									v-model="field.value" :placeholder="field.placeholder"
								/>

								<base-input :id="`input-${field.fieldName}`"
									v-if="field.type == 'checkbox'" :checked="field.value"
									type="checkbox" v-model="field.value" :placeholder="field.placeholder"
								/>

								<Multiselect
									v-if="field.type == 'chosen' || field.type == 'chosen-m' || field.type == 'chosen-mu'"
									v-model="field.value" :options="filteredValueArray(field)"
									:multiple="field.type == 'chosen-m' || field.type == 'chosen-mu'"
									:close-on-select="true" :clear-on-select="false" :preserve-search="true"
									placeholder="Pick required options" label="desc" track-by="value"
									:preselect-first="true" class="mb-4" id="multiChosen">
									<template slot="selection" slot-scope="{ values, search, isOpen }">
										<span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
											{{ values.length }} options selected
										</span>
									</template>
								</Multiselect>
							</div>
						</div>
					</div>

					<!-- Email mode -->
					<div class="row" v-if="!isDirectMode && orchestrationType && !showDone">
						<div class="col">
							<h3>Email address (to send you a report on completion)</h3>
							<base-input id="inputEmail" ref='email' v-model='emailAddress' placeholder="Enter email address"
								@input="emailValid = validateEmail(emailAddress)"
								addon-left-icon="ni ni-email-83"></base-input>
						</div>
					</div>

					<base-button type="primary" id="btnProvision" icon="ni ni-send" class="mt-4"
						@click="scheduleProvisioning" v-if="!showDone"
						:disabled="(!isDirectMode && !emailValid) || !bs_blob || !orchestrationType || disableProvisoningPlanBtn || (custName.length === 0 || !custName.trim())">
						Provision system
					</base-button>

					<!-- Reset/clear provisioning -->
					<button id="btnResetProvision" type="button" class="btn btn-link mt-4 mx-auto d-block"
						v-if="bs_blob && !showDone" @click="clearProvisioning">
						Clear provisioning and start over?
					</button>

					<!-- Show events table if in direct mode -->
					<div class="row" v-if="isDirectMode && orchestrationType && showDone">
						<div class="col">
							<Events action="Provisioning" :jobId="amJobId" :siteId="0" :timestamp="0"
								@completed="prepareDownloads" @timeout="timeoutOccurred = true"
								@retry="clearProvisioning" />
						</div>
					</div>

					<!-- Show email alert if not in direct mode -->
					<div class="row" v-if="!isDirectMode && showDone">
						<div class="col text-center">
							<h2>Orchestration in progess!</h2>
							<p>You should receive an email within 3 hours with additional information.</p>

							<!-- Reset/clear provisioning -->
							<button id="btnResetProvision" type="button" class="btn btn-link mt-4 mx-auto d-block"
								@click="clearProvisioning">
								Run another provision?
							</button>
						</div>
					</div>
				</card>
			</div>

			<!-- Download reports if in direct mode -->
			<DownloadReports v-if="isDirectMode && downloadVisible && !timeoutOccurred" :files="files" action="Provisioning"
				:scheduleId="amJobId">
				<template v-slot:action>
					<!-- Reset/clear provisioning -->
					<button id="btnResetProvision" type="button" class="btn btn-link mt-4 mx-auto d-block"
						@click="clearProvisioning">
						Clear provisioning and start over?
					</button>
				</template>
			</DownloadReports>
		</div>
	</tab-pane>
</template>

<script>
// Components
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";

import Events from "@/components/Events.vue";
import DownloadReports from "@/components/DownloadReports.vue";
import PartnerType from "@/components/PartnerType.vue";

import { getImgUrl } from "@/utils/images.js";
import { validateEmail } from "@/utils/email.js";

import { mapActions, mapGetters } from 'vuex';

// API services
import provisioningService from "@/api/provisioningService";
import { signIn } from "@/services/externalAuthManager"

export default {
	name: "Provisioning",
	components: {
		VueDropzone: vue2Dropzone,
		Multiselect,
		Events,
		DownloadReports,
		PartnerType
	},
	computed: {
		...mapGetters([
			"getToken",
			"getAllowedProvisioningTypes",
			"isMitel",
			"isDirectMode",
			"getProvisioningTypes",
			"getGroupProfile",
			"getExternalAuthToken",
			"isProvisioningAuth"
		]),

		resultQuery() {
			// Only want to search by siteName
			if (this.search) {
				// Filter out vendors by whether they're allowed or not
				const results = this.orchestrationVendors.filter(vendor => this.checkAllowed(vendor));
				return results.filter((item) => {
					return this.search.toLowerCase().split(' ').every(v => item.desc.toLowerCase().includes(v))
				})
			} else {
				return this.orchestrationVendors.filter(vendor => this.checkAllowed(vendor));
			}
		}
	},
	data() {
		return this.initialState()
	},
	async mounted() {
		await this.$store.dispatch("initializeProvisioningTypes");

		// Ogranise orchestration vendors alphabetically by description
		const vendors = this.getProvisioningTypes;
		if (vendors) {
			const sortedVendors = vendors.sort(function (a, b) {
				let nameA = a.desc.toUpperCase(); // ignore upper and lowercase
				let nameB = b.desc.toUpperCase(); // ignore upper and lowercase
				if (nameA < nameB) {
					return -1; //nameA comes first
				}
				if (nameA > nameB) {
					return 1; // nameB comes first
				}
				return 0;  // names must be equal
			});

			this.orchestrationVendors = sortedVendors;
		}

		// Automatically select orchestration type if possible
		if (this.isProvisioningAuth) {
			this.selectOrchestration(this.getExternalAuthToken.identifier);
		}

		// Prevent browser opening file if dropped into window.
		// Show error message if user attempts to
		window.addEventListener("dragover", (event) => {
			event.preventDefault();
		})

		window.addEventListener("drop", (event) => {
			event.preventDefault();

			if ((!this.orchestrationType.name || (this.orchestrationType.externalSSO && !this.isProvisioningAuth))) {
				this.error.message = "Please select a target platform type before uploading a file!"
				this.error.show = true;
			}
		})
	},
	methods: {
		filteredValueArray(field) {
			if (!this.getGroupProfile["SPAVar_" + field.fieldName]) {
				return field.valueArray
			} else {
				const permitted = this.getGroupProfile["SPAVar_" + field.fieldName].split(',')
				let filteredArray = []
				field.valueArray.forEach(x => {
					if (permitted.includes(x.value)) {
						filteredArray.push(x)
					}
				})
				return filteredArray
			}
		},
		...mapActions([
			"clearExternalAuth"
		]),
		initialState() {
			return {
				bs_blob: "",
				showDone: false,

				search: "",

				hideDropzone: false,
				dropzoneOptions: {
					url: `${window.location.protocol}//${document.location.hostname}:8809/tempfile`,
					thumbnailWidth: 150,
					maxFilesize: 20000,
					maxFiles: 1, // Handle exceeds
					timeout: 600000, // Override default of 30 seconds to 10 minutes

					accept: (file, done) => {
						file.done = true;

						const reader = new FileReader();
						reader.addEventListener("loadend", function (event) {
							this.bs_blob = new Blob([event.target.result], {
								type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
							},
								"migrationPlan.xlsx");
						}.bind(this)); // Bind "Data" from anon function (from Vue)
						reader.readAsArrayBuffer(file);

						file.upload.progress = 100;
						file.upload.bytesSent = file.upload.total;

						done();
					}
				},

				// Orchestration
				orchestrationType: "",
				orchestrationVendors: [],
				custName: "",
				sowId: "",
				phaseId: "",
				selectedPartner: "",

				timeoutOccurred: false,

				emailAddress: "",
				emailValid: false,

				// Data for events (asset manager)
				amJobId: 0,

				files: [],
				downloadVisible: false,

				// Details used for error prompt
				error: {
					show: false,
					message: "",
				},

				disableProvisoningPlanBtn: false,

				getImgUrl,
				validateEmail
			}
		},

		checkAllowed(orchestrator) {
			let allowed = (!this.getAllowedProvisioningTypes || this.getAllowedProvisioningTypes.indexOf(orchestrator.name) > -1);
			if (orchestrator.mitel == 1 && this.isMitel != '1') {
				return false;
			}
			return allowed;
		},

		selectOrchestration(orchestratorName) {
			let o = this.getProvisioningTypes.find(o => o.name.toUpperCase() == orchestratorName.toUpperCase());

			// Clear the token if the user selects a different external SSO provider.
			if (o.externalSSO && this.isProvisioningAuth && o.name.toUpperCase() != this.getExternalAuthToken.identifier) {
				this.clearExternalAuth();
			}

			this.orchestrationType = o;

			// Clear error
			this.error.show = false;
			this.error.message = "";
		},

		clearProvisioning() {
			if (this.$refs.dropzone) {
				this.$refs.dropzone.removeAllFiles();
			}

			this.clearExternalAuth();
			Object.assign(this.$data, this.initialState());

			// Set orchestration vendors again
			this.orchestrationVendors = this.getProvisioningTypes;
		},

		async authProvisioning() {

			this.clearExternalAuth();

			var provisioningTarget = this.getProvisioningTypes.find(o => o.name.toUpperCase() === this.orchestrationType.name.toUpperCase())
			if (!provisioningTarget) {
				return;
			}

			signIn('Prov', this.orchestrationType.name, provisioningTarget.settings);
		},

		// Attach JWT to Dropzone when a file is uploaded
		attachJWT(_file, xhr) {
			xhr.setRequestHeader('Authorization', `Bearer ${this.getToken}`);
		},

		async scheduleProvisioning() {
			// Check that a file has been uploaded
			if (!this.bs_blob) {
				this.error.message = "Please upload a file and try again!"
				this.error.show = true;

				return;
			}

			// Check that a vendor has been picked
			if (!this.orchestrationType) {
				this.error.message = "Please pick a target vendor and try again!"
				this.error.show = true;

				return;
			}

			// Check if the vendor picked is external SSO and that there is a token present
			if (this.orchestrationType.externalSSO && !this.isProvisioningAuth) {
				this.error.message = `Please authenticate with ${this.orchestrationType.desc} and try again!`
				this.error.show = true;

				return;
			}

			// Check that a customer name is present
			if (this.custName.length === 0 || !this.custName.trim()) {
				this.error.message = "Please enter a customer name and try again!"
				this.error.show = true;

				return;
			}

			// Validate the SOW number if a validation rule is set.
			if (this.getGroupProfile.SPASOW && this.getGroupProfile.SPASOWRegex) {
				const reg = new RegExp(this.getGroupProfile.SPASOWRegex);
				if (!reg.test(this.sowId)) {
					this.error.message = `Please enter a valid ${this.getGroupProfile.SPASOWLabel} and try again!`;
					this.error.show = true;

					return;
				}
			}

			// Validate the Phase Id if a validation rule is set.
			if (this.getGroupProfile.SPAPhase && this.getGroupProfile.SPAPhaseRegex) {
				const reg = new RegExp(this.getGroupProfile.SPAPhaseRegex);
				if (!reg.test(this.phaseId)) {
					this.error.message = `Please enter a valid ${this.getGroupProfile.SPAPhaseLabel} and try again!`;
					this.error.show = true;

					return;
				}
			}

			// Validate partner type
			if (this.$refs.PartnerType.getPartnerTypes().length > 0 && this.selectedPartner == '') {
				this.error.message = "Please select a partner type.";
				this.error.show = true;
				return;
			}

			// Check that email address is provided for 'email' mode and that it's valid
			if (!this.isDirectMode && !this.emailAddress) {
				this.error.message = "Please enter an email address where the reports will be sent to!"
				this.error.show = true;

				return;
			}

			if (!this.isDirectMode && this.emailAddress) {
				const valid = validateEmail(this.emailAddress);

				if (!valid) {
					this.error.message = "The email address provided is invalid! Please try again."
					this.error.show = true;

					return;
				}
			}

			this.disableProvisoningPlanBtn = true;

			// Clear previous errors
			this.error.message = "";
			this.error.show = false;

			// this.$refs.dropzone.removeAllFiles();
			this.hideDropzone = true;

			const provisioningDetails = {
				"vendorType": this.orchestrationType.name,
				"outputFormat": this.orchestrationType.name,
				"email": this.emailAddress,
				"filename": this.orchestrationFilename,
				"emailTemplate": (this.getGroupProfile.emailTemplate || ""),
				"custName": this.custName,
				"sowId": this.sowId,
				"phaseId": this.phaseId,
				"partnerIdentifier": this.selectedPartner
			}

			if (this.isProvisioningAuth && this.orchestrationType.name.toUpperCase() === this.getExternalAuthToken.identifier) {
				provisioningDetails.token = this.getExternalAuthToken;
			}

			this.orchestrationType.fields.forEach(field => {
				var value;
				if (Array.isArray(field.value)) {
					value = field.value.map(f => f.value)
				} else {
					if (field.type == 'chosen') {
						value = field.value.value || '';
					} else {
						value = field.value || '';
					}
				}
				provisioningDetails[field.fieldName] = value;
			})

			const data = new FormData()
			data.append('options', JSON.stringify(provisioningDetails))
			data.append('migrationPlan', this.bs_blob)

			let config = {
				timeout: 60 * 10 * 1000, // 10 mins
				header: {
					'Content-Type': 'multipart/form-data'
				}
			}

			try {
				const response = await provisioningService.getJobs(data, config);
				this.amJobId = response.data.jobId;
				this.showDone = true;
				this.clearExternalAuth();
			} catch (e) {
				this.error.message = e;
				this.error.show = true;

				return;
			} finally {
				this.disableProvisoningPlanBtn = false;
			}
		},

		prepareDownloads() {
			this.downloadVisible = true;

			// Prepare provisioning document
			this.files.push({
				document: "Provisioning Results",
				parameters: {
					jobId: this.amJobId
				},
				type: "prov",
				docType: "download",
			});
		}
	}
}
</script>

<style scoped>
.disabled {
	pointer-events: none;
}
</style>