export default {     
 
  migrationSettings : {
    "source": {	
      "scans": [],
      "voicemail": { "filename":"", "groupIdentifier": "group", "f_dn" : "Extension", "f_firstname" : "FirstName", "f_lastname" : "LastName" },
      "network": { "filename":"", "f_dn" : "Number", "f_mac": "MAC", "f_ip": "IP", "f_switch":"Switch", "f_port": "Port", "f_model":"Model","f_lastSeen":"Last Seen" },
      "recorder": { "filename":"", "f_dn" : "Primary Extension Name" },
      "devicestate": { "filename":"", "f_dn" : "Number", "f_state": "State", "f_ip": "IP Address","f_mac": "MAC Address", "f_desc": "Device Description"},
      "AD": { "filename":"", 
        "f_dn" : ["Telephonenumber","Phone"], 
        "f_ddi": ["DDI","Phone"], 
        "f_cn": "CN", 
        "f_dName": ["DisplayName","FullName","DirectoryName"], 
        "f_sam": "SAMAccountName", 
        "f_comp": "Company", 
        "f_dept": "Department", 
        "f_email": ["E-Mail","Email","EmailAddress","Mail"], 
        "f_sip": "SIPAddress", 
        "f_uid": "ObjectSID", 
        "f_upn": ["UPN","UserPrincipalName"], 
        "f_mobile": ["Mobile","MobilePhone","Cell","CellPhone"],
        "fuzzy":true},
      "cdr": {"tenantID":-1,"agentId":"", "startDateTime":null,"endDateTime":null},
      "ddi" : { "filename" : "" },
      "custom": { "filename" : "" },
      "devices": { "filename":"", "f_dn" : "Number", "userId" : "userId", "MAC" : "macAddress", "deviceType": "deviceType" },
      "regions": [],
      "regionNames" : [],
      "deleteTempFiles": true,
      "deleteTempFilesDelayed": true
    },
    "translations": {
      "cos":[	],
      "cor":[	],
      "handset":[ ],
      "ddi":[ ],
      "custom": [ ],
      "ecrf": {}
    },
    "checks": { 
      "excludeDeviceNoUseBefore": false, 
      "excludeProfileNoUseBefore": false,
      "excludeDevicesNoNetwork": false,
      "adLooseMatchLocation": null,
      "nameCorrection": "",
      "removePlus": true,
      "insertPlus": false,
      "ignoreAllCDR": false,
      "ignoreCDRdest": false,
      "addTargetCDR": false,
      "ignoreAllNetwork": true,
      "ignoreCrossLocationForNull": true,
      "includeDevMobilityLoginAfter":null,
      "remapDevices": true,
      "autoRemoveDuplicates": true,
      "removeEmptySheets":true,
      "removeDuplicateDN":false,
      "moveIPCommunicator":false,
      "forceAllDevicesToProfiles":false,
      "minExtnLength": 4,
      "maxExtnLength": 5,
      "voicemailNumber":'',
      "prefixRemove":'',
      "removeColumns": true,
      "removeColumns2": false,
      "includeInternal": false,
      "checkHandsets":''
    },
    "orchestrationOptions": {},
    "output": {	"outputFormat":"excel", "userSuffix":"", "filename":"" },
    "callback": { "method": "POST", "url" : "" }
  },

}

 